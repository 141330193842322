define("discourse/plugins/automation/admin/controllers/admin-plugins-discourse-automation-edit", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n"], function (_exports, _controller, _object, _computed, _runloop, _service, _ajax, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AutomationEdit extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    error = null;
    isUpdatingAutomation = false;
    isTriggeringAutomation = false;
    static #_2 = (() => dt7948.g(this.prototype, "automation", [(0, _computed.reads)("model.automation")]))();
    #automation = (() => (dt7948.i(this, "automation"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "scriptFields", [(0, _computed.filterBy)("automationForm.fields", "targetType", "script")]))();
    #scriptFields = (() => (dt7948.i(this, "scriptFields"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "triggerFields", [(0, _computed.filterBy)("automationForm.fields", "targetType", "trigger")]))();
    #triggerFields = (() => (dt7948.i(this, "triggerFields"), void 0))();
    get nextPendingAutomationAtFormatted() {
      const date = this.model?.automation?.next_pending_automation_at;
      if (date) {
        return moment(date).format("LLLL");
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "nextPendingAutomationAtFormatted", [(0, _object.computed)("model.automation.next_pending_automation_at")]))();
    saveAutomation() {
      this.setProperties({
        error: null,
        isUpdatingAutomation: true
      });
      return (0, _ajax.ajax)(`/admin/plugins/discourse-automation/automations/${this.model.automation.id}.json`, {
        type: "PUT",
        data: JSON.stringify({
          automation: this.automationForm
        }),
        dataType: "json",
        contentType: "application/json"
      }).then(() => {
        this.send("refreshRoute");
      }).catch(e => this._showError(e)).finally(() => {
        this.set("isUpdatingAutomation", false);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "saveAutomation", [_object.action]))();
    onChangeTrigger(id) {
      if (this.automationForm.trigger && this.automationForm.trigger !== id) {
        this._confirmReset(() => {
          (0, _object.set)(this.automationForm, "trigger", id);
          this.saveAutomation();
        });
      } else if (!this.automationForm.trigger) {
        (0, _object.set)(this.automationForm, "trigger", id);
        this.saveAutomation();
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "onChangeTrigger", [_object.action]))();
    onManualAutomationTrigger(id) {
      this._confirmTrigger(() => {
        this.set("isTriggeringAutomation", true);
        return (0, _ajax.ajax)(`/automations/${id}/trigger.json`, {
          type: "post"
        }).catch(e => this.set("error", (0, _ajaxError.extractError)(e))).finally(() => {
          this.set("isTriggeringAutomation", false);
        });
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "onManualAutomationTrigger", [_object.action]))();
    onChangeScript(id) {
      if (this.automationForm.script !== id) {
        this._confirmReset(() => {
          (0, _object.set)(this.automationForm, "script", id);
          this.saveAutomation();
        });
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "onChangeScript", [_object.action]))();
    _confirmReset(callback) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_automation.confirm_automation_reset"),
        didConfirm: () => {
          return callback && callback();
        }
      });
    }
    _confirmTrigger(callback) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_automation.confirm_automation_trigger"),
        didConfirm: () => {
          return callback && callback();
        }
      });
    }
    _showError(error) {
      this.set("error", (0, _ajaxError.extractError)(error));
      (0, _runloop.schedule)("afterRender", () => {
        window.scrollTo(0, 0);
      });
    }
  }
  _exports.default = AutomationEdit;
});