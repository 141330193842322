define("discourse/plugins/automation/admin/templates/components/form-error", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if error}}
    <div class="alert alert-error form-errors">
      {{html-safe error}}
    </div>
  {{/if}}
  */
  {
    "id": "ddlCpJnb",
    "block": "[[[41,[30,0,[\"error\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-error form-errors\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"error\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `error` property path was used in the `discourse/plugins/automation/admin/templates/components/form-error.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.error}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `error` property path was used in the `discourse/plugins/automation/admin/templates/components/form-error.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.error}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/automation/admin/templates/components/form-error.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});